import {FC} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'

type Props = {
  handleClose: () => void | undefined
}

const ProjectModalHeader: FC<Props> = ({handleClose}) => {
  const intl = useIntl()
  return (
    <div className='modal-header'>
      <h2>{intl.formatMessage({id: 'MODAL.TITLE.PROJECT'})}</h2>
      {/* begin::Close */}
      <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {ProjectModalHeader}
