/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useRef, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Outlet, useParams, useNavigate} from 'react-router-dom'
import {useMutation} from 'react-query'
import Swal from 'sweetalert2'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {KTSVG} from '../../../../_metronic/helpers'
import {updateOnboardingStatus} from '../core/_requests'
import {Loading} from '../../../../_metronic/partials/components/loading/Loading'
import {useAuth} from '../../../modules/auth'

const ManagerOnboardingPageWrapper: FC = () => {
  const intl = useIntl()
  const {step} = useParams()
  const navigate = useNavigate()
  const {currentUser, setCurrentUser} = useAuth()
  const stepperRefOnboarding = useRef<HTMLDivElement | null>(null)
  const stepperOnboarding = useRef<StepperComponent | null>(null)
  const stepperNavRef = useRef<Array<HTMLDivElement | null>>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const navItems = [
    {
      number: 1,
      title: intl.formatMessage({id: 'ONBOARDING.TITLE.STEP_1'}),
      description: intl.formatMessage({id: 'ONBOARDING.DESCRIPTION.STEP_1'}),
    },
    {
      number: 2,
      title: intl.formatMessage({id: 'ONBOARDING.TITLE.STEP_2'}),
      description: intl.formatMessage({id: 'ONBOARDING.DESCRIPTION.STEP_2'}),
    },
    {
      number: 3,
      title: intl.formatMessage({id: 'ONBOARDING.TITLE.STEP_3'}),
      description: intl.formatMessage({id: 'ONBOARDING.DESCRIPTION.STEP_3'}),
    },
    {
      number: 4,
      title: intl.formatMessage({id: 'ONBOARDING.TITLE.STEP_4'}),
      description: intl.formatMessage({id: 'ONBOARDING.DESCRIPTION.STEP_4'}),
    },
    {
      number: 5,
      title: intl.formatMessage({id: 'ONBOARDING.TITLE.STEP_5'}),
      description: intl.formatMessage({id: 'ONBOARDING.DESCRIPTION.STEP_5'}),
    },
  ]

  useEffect(() => {
    stepperOnboarding.current = StepperComponent.createInsance(
      stepperRefOnboarding.current as HTMLDivElement
    )
  }, [])

  useEffect(() => {
    if (step) {
      stepperNavRef.current.map((element, index) => {
        element?.classList.remove('current')
        element?.classList.remove('completed')
        if (index < parseInt(step) - 1) {
          element?.classList.add('completed')
        }
      })
      stepperNavRef.current[parseInt(step) - 1]?.classList.add('current')
    } else {
      stepperNavRef.current[0]?.classList.add('current')
    }
  }, [step])

  const nextStep = () => {
    const nextStep = step ? parseInt(step!) + 1 : 2
    return navigate(`/onboarding/${nextStep}`)
  }

  const prevStep = () => {
    return navigate(`/onboarding/${parseInt(step!) - 1}`)
  }

  const handleFinish = () => {
    setIsLoading(true)
    update.mutateAsync()
  }

  const update = useMutation(() => updateOnboardingStatus(true), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: (response) => {
      setIsLoading(false)
      if (!currentUser?.onboarded) {
        setCurrentUser({...currentUser!, onboarded: 1})
      }
      return navigate('/dashboard')
    },
    onError: (error: any) => {
      setIsLoading(false)
      Swal.fire({
        icon: 'warning',
        text: error.response.data.errors.email[0],
      })
    },
  })

  return (
    <div className='d-flex flex-column flex-root' id='kt_app_root'>
      <div
        ref={stepperRefOnboarding}
        className='d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep'
        id='kt_onboarding_stepper'
      >
        {/* <!--begin::Aside--> */}
        <div className='d-flex flex-column flex-lg-row-auto w-lg-350px w-xl-500px'>
          <div className='d-flex flex-column position-lg-fixed top-0 bottom-0 w-lg-350px w-xl-500px scroll-y bgi-size-cover bgi-position-center'>
            {/* <!--begin::Header--> */}
            <div className='d-flex flex-center py-10 py-lg-15'>
              {/* <!--begin::Logo--> */}
              <a href='../../demo1/dist/index.html'>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/taskrush.svg')}
                  className='h-70px'
                />
              </a>
              {/* <!--end::Logo--> */}
            </div>
            {/* <!--end::Header--> */}
            {/* <!--begin::Body--> */}
            <div className='d-flex flex-row-fluid justify-content-center p-10'>
              {/* <!--begin::Nav--> */}
              <div className='stepper-nav'>
                {navItems.map((item, index) => (
                  <div
                    key={index}
                    ref={(el) => (stepperNavRef.current![index] = el)}
                    className='stepper-item'
                    data-kt-stepper-element='nav'
                  >
                    {/* <!--begin::Wrapper--> */}
                    <div className='stepper-wrapper'>
                      {/* <!--begin::Icon--> */}
                      <div className='stepper-icon rounded-3'>
                        <i className='stepper-check fas fa-check'></i>
                        <span className='stepper-number'>{item.number}</span>
                      </div>
                      {/* <!--end::Icon--> */}
                      {/* <!--begin::Label--> */}
                      <div className='stepper-label'>
                        <h3 className='stepper-title fs-2'>{item.title}</h3>
                        <div className='stepper-desc fw-normal'>{item.description}</div>
                      </div>
                      {/* <!--end::Label--> */}
                    </div>
                    {/* <!--end::Wrapper--> */}
                    {/* <!--begin::Line--> */}
                    {index + 1 < navItems.length && <div className='stepper-line h-40px'></div>}
                    {/* <!--end::Line--> */}
                  </div>
                ))}
              </div>
              {/* <!--end::Nav--> */}
            </div>
            {/* <!--end::Body--> */}
            {/* <!--begin::Footer--> */}
            <div className='d-flex flex-center flex-wrap px-5 pt-5 pb-10'>
              <button
                type='button'
                className='btn btn-lg btn-link link-primary p-0'
                onClick={handleFinish}
              >
                {intl.formatMessage({id: 'GENERAL.SKIP_TUTORIAL'})}
              </button>
              {/* <a className='fs-4' href='#'>
              </a> */}
            </div>
            {/* <!--end::Footer--> */}
          </div>
        </div>
        {/* <!--begin::Aside--> */}
        {/* <!--begin::Body--> */}
        <div className='d-flex flex-column flex-lg-row-fluid py-10'>
          {/* <!--begin::Content--> */}
          <div className='d-flex flex-center flex-column flex-column-fluid'>
            {/* <!--begin::Wrapper--> */}
            <div className='w-lg-800px p-10 px-lg-0 py-lg-15 mx-auto'>
              {/* <!--begin::Form--> */}
              <Outlet />

              {/* <!--begin::Actions--> */}
              <div className='d-flex pt-15'>
                {parseInt(step!) > 1 && (
                  <div className='mr-2'>
                    <button
                      type='button'
                      className='btn btn-lg btn btn-outline btn-outline-dashed btn-outline-primary btn-active-primary me-5'
                      disabled={step === undefined || parseInt(step!) <= 1}
                      onClick={prevStep}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr063.svg'
                        className='svg-icon-3 me-1'
                      />
                      {intl.formatMessage({id: 'GENERAL.BACK'})}
                    </button>
                  </div>
                )}
                <div>
                  {parseInt(step!) === navItems.length ? (
                    <button type='button' className='btn btn-lg btn-primary' onClick={handleFinish}>
                      <span className='indicator-label'>
                        {intl.formatMessage({id: 'GENERAL.DO_IT_LATTER'})}
                      </span>
                    </button>
                  ) : (
                    <button
                      type='button'
                      className='btn btn-lg btn-light-primary'
                      onClick={nextStep}
                    >
                      {intl.formatMessage({id: 'GENERAL.NEXT_STEP'})}{' '}
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-1 me-0'
                      />
                    </button>
                  )}
                </div>
              </div>
              {/* <!--end::Actions--> */}
            </div>
            {/* <!--end::Wrapper--> */}
          </div>
          {/* <!--end::Content--> */}
        </div>
        {/* <!--end::Body--> */}
      </div>
      {isLoading && <Loading />}
    </div>
  )
}

export {ManagerOnboardingPageWrapper}
