import {forwardRef, useCallback, useEffect} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import Swal from 'sweetalert2'
import {ID, isNotEmpty, KTSVG, QUERIES} from '../../../../../../_metronic/helpers'
import {CustomToggleProps} from '../../../../wiki/components/wiki-list/table/WikiTable'
import {Toast} from '../../../../../../_metronic/partials/widgets'
import {deleteAttachement, getAttachements} from '../TaskAttachment/core/_requests'
import {createTaskAttachement} from '../core/_requests'

type Props = {
  taskId: ID | ID
}

const OverviewAttachement = ({taskId}: Props) => {
  const intl = useIntl()

  const initialValues = {
    file: '',
    task_id: '',
  }

  const queryClient = useQueryClient()

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.file)) {
          await createTaskAttachement(values).then((response) => {
            Toast.fire({
              icon: 'success',
              title: intl.formatMessage(
                {id: 'ALERT.LABEL.SUCCESS_CREATE_ITEM'},
                {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.TASKATTACHEMENT'})}
              ),
              customClass: {
                popup: 'bg-light-success',
                title: 'text-success',
              },
            })
            queryClient.invalidateQueries(`${QUERIES.TASKS_ATTACHEMENT_LIST}`)
            resetForm()
          })
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  useEffect(() => {
    formik.setFieldValue('task_id', taskId)
  }, [])

  const handleCreate = useCallback(
    async (e: any) => {
      formik.setFieldValue('file', e.target.files[0])
      formik.submitForm()
      e.target.value = null
      formik.setFieldValue('task_id', taskId)
    },
    [formik]
  )

  const deleta = async (id: ID) => {
    const CurrentTheme = window.localStorage.getItem('kt_theme_mode_value') || ''
    let tema = ''
    if (CurrentTheme === 'dark') {
      tema = '#353b48'
    } else {
      tema = '#fff'
    }

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false,
    })

    swalWithBootstrapButtons
      .fire({
        title: intl.formatMessage({id: 'ALERT.LABEL.ARE_YOU_SURE'}),
        html: intl.formatMessage({id: 'ALERT.LABEL.IT_CAN_NOT_BE_UNDONE'}),
        icon: 'warning',
        background: tema,
        showCancelButton: true,
        confirmButtonText: 'Sim, pode excluir',
        cancelButtonText: 'Não, cancele!',
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          return await deleteItem.mutateAsync(id)
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          Toast.fire({
            icon: 'warning',
            title: intl.formatMessage(
              {id: 'ALERT.LABEL.SUCCESS_CANCEL_ITEM'},
              {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.TASKATTACHEMENT'})}
            ),
            customClass: {
              popup: 'bg-light-danger',
              title: 'text-danger',
            },
          })
        }
      })
  }
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
  }

  const deleteItem = useMutation((id: ID) => deleteAttachement(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      Toast.fire({
        icon: 'success',
        title: intl.formatMessage(
          {id: 'ALERT.LABEL.SUCCESS_CANCEL_ITEM'},
          {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.TASKATTACHEMENT'})}
        ),
        customClass: {
          popup: 'bg-light-success',
          title: 'text-success',
        },
      })

      cancel(true)
    },
  })
  const {refetch, data: response} = useQuery(
    `${QUERIES.TASKS_ATTACHEMENT_LIST}`,
    () => {
      return getAttachements(taskId)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const CustomToggle = forwardRef((props: CustomToggleProps, ref: React.Ref<HTMLAnchorElement>) => (
    <a
      href=''
      className='text-hover-primary'
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        props.onClick(e)
      }}
    >
      {props.children}
      <span style={{paddingLeft: '5px'}}></span>
    </a>
  ))

  return (
    <>
      <form id='kt_modal_add_contract_form' className='form' onSubmit={handleCreate} noValidate>
        <div className='row align-items-center mb-4'>
          <div className='col-12 col-md-6 d-flex justify-content-end pe-0'>
            <div className='mb-4'>
              <label className='required fw-bold mb-1'>
                {intl.formatMessage({id: 'FORM.INPUT.NAME.NEW_FILE'})}
              </label>

              <input
                type='file'
                name='file'
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault()
                }}
                id='file'
                className='form-control form-control-solid py-2  rounded fw-normal'
                accept='.png, .jpg, .jpeg, .pdf, .doc, .docx, .zip, .rar, .sql, .xls, .xlsx'
                onChange={handleCreate}
                //   ref={ref}
              />
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-12 p-0 shadow bg-body fs-6'>
            <div className='min-h-225px overflow-scroll'>
              {/* IT IS HIDDEN UNTIL WE HAVE CONTRACTS TO PROJECT */}
              <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                <thead>
                  <tr className='text-start text-gray-400 fw-bold fs-8 text-uppercase gs-0'>
                    <th className='min-w-300px p-5 shadow-sm '>
                      {intl.formatMessage({id: 'ATTACHEMENT.TITLE'})}
                    </th>
                  </tr>
                </thead>
                {response?.data.map((rs) => {
                  return (
                    <tbody className='text-gray-600 fw-bold'>
                      <tr>
                        <td colSpan={1}>
                          <div className='d-flex align-items-center px-4'>
                            <KTSVG
                              path='/media/icons/duotune/files/fil003.svg'
                              className='svg-icon svg-icon-2x svg-icon-primary me-4'
                            />
                            <a href={rs.file} target='_blank'>
                              {rs.file_name}
                            </a>
                          </div>
                        </td>
                        <td colSpan={1}>
                          <button type='button' className='btn' onClick={() => deleta(rs.id)}>
                            <KTSVG
                              path='/media/icons/duotune/general/gen027.svg'
                              className='svg-icon svg-icon-2x svg-icon-primary me-4'
                            />
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  )
                })}
              </table>
            </div>
          </div>
        </div>
      </form>
      {formik.isSubmitting}
    </>
  )
}

export {OverviewAttachement}
