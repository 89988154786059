import {useState, ReactNode} from 'react'
import {useParams} from 'react-router-dom'
import {Step1} from './Step1'
import {Step2} from './Step2'
import {Step3} from './Step3'
import {Step4} from './Step4'
import {Step5} from './Step5'

const StepsManagerWrapper = () => {
  const {step} = useParams()

  const stepsList = [Step1, Step2, Step3, Step4, Step5]
  let CurrentStep = Step1

  if (step) {
    CurrentStep = stepsList[parseInt(step) - 1]
  }

  return <CurrentStep />
}

export {StepsManagerWrapper}
