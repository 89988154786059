/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import ptbrLocale from '@fullcalendar/core/locales/pt-br'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { ColumnInstance, Row, useTable } from 'react-table'
import { useQuery } from 'react-query'
import { DateSelectArg } from '@fullcalendar/core'
import interactionPlugin from '@fullcalendar/interaction' // needed for dayClick
import clsx from 'clsx'
import { ChartsWidget9 } from '../../../../_metronic/partials/widgets/charts/ChartsWidget9'
import { ProjectCard } from '../../../../_metronic/partials/content/cards/ProjectCard'
import { ChartsWidget14 } from '../../../../_metronic/partials/widgets'
import { useAuth } from '../../../modules/auth'
import { ContractsColumns } from './components/contracts-columns/_columns'
import { Loading } from '../../../../_metronic/partials/components/loading/Loading'
import { CustomRow } from './components/contracts-columns/CustomRow'
import { CustomHeaderColumn } from './components/contracts-columns/CustomHeaderColumn'
import { Contracts } from '../../../modules/contracts/components/contracts-list/core/_models'
import { getContractExpire } from '../../../modules/contracts/components/contracts-list/core/_requests'
import { ContractsPlaceholder } from '../../../modules/contracts/components/contracts-list/components/placeholder/ContractsPlaceholder'
import {
  getDashboardInfo,
  getProjects,
  getRecurringProjects,
  getOpenedProjects,
  getRecentActivities,
  getTimeTrackingBySquadRandom,
  getTimeTrackingBySquadRandomMonth,
} from './core/_requests'
import {
  ID,
  QUERIES,
  isNotEmpty,
  secondsToHoursFormatNotSeconds,
  toAbsoluteUrl,
  diffInYears,
  KTSVG,
} from '../../../../_metronic/helpers'
import { User } from '../../../modules/time-tracking/components/time-tracking-list/core/_models'
import {
  getTeamsByMonth,
  getTeamsByWeek,
} from '../../../modules/time-tracking/components/time-tracking-list/core/_requests'
import { OverviewRecentActivitiesModel } from '../../../modules/project/components/core/_models'
import { useListView } from '../../../modules/project/components/tasks-list/core/ListViewProvider'
import { useThemeMode } from '../../../../_metronic/partials'
import { RecentActivities } from './core/_models'
import { UpgradePlanWidget } from '../../../../_metronic/partials/widgets/_new/engage/UpgradePlanWidget'
import { ProjectCreateModalStepper } from '../../../../_metronic/partials/modals/project-create-modal-stepper/ProjectCreateModalStepper'
import { TaskModal } from '../../../../_metronic/partials/modals/task/TaskModal'

const DashboardManagerPage: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const intl = useIntl()
  const today = new Date()
  const { mode } = useThemeMode()
  const [contracts, setContracts] = useState<Contracts[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [currentFilter, setCurrentFilter] = useState('0')

  useEffect(() => {
    ; (async () => {
      await getContractExpire().then((response) => {
        setContracts(response.data)
        setIsLoading(false)
      })
    })()
  }, [])

  const contractsData = useMemo(() => contracts, [contracts])
  const columns = useMemo(() => ContractsColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data: contractsData,
  })
  const TemaAtual = window.localStorage.getItem('kt_theme_mode_value') || ''

  const {
    isLoading: isDashboardLoading,
    data: dashboardInfo,
    refetch,
    error,
  } = useQuery(
    `${QUERIES.DASHBOARD_INFO}`,
    () => {
      return getDashboardInfo()
    },
    {
      cacheTime: 0,
      onError: (err) => {
        console.error(err)
      },
      refetchOnWindowFocus: false,
    }
  )

  const {
    isLoading: isProjectsLoading,
    data: dashboardProjects,
    refetch: projectsRefetch,
    error: errorRefetch,
  } = useQuery(
    `${QUERIES.DASHBOARD_PROJECTS}`,
    () => {
      return getProjects()
    },
    {
      cacheTime: 0,
      onError: (err) => {
        console.error(err)
      },
      refetchOnWindowFocus: false,
    }
  )

  const {
    isLoading: isRecurringProjectsLoading,
    data: recurringProjects,
    refetch: recurringProjectsRefetch,
    error: errorRecurringProjects,
  } = useQuery(
    `${QUERIES.DASHBOARD_RECURRING_PROJECTS}`,
    () => {
      return getRecurringProjects()
    },
    {
      cacheTime: 0,
      onError: (err) => {
        console.error(err)
      },
      refetchOnWindowFocus: false,
    }
  )

  const {
    isLoading: isOpenedProjectsLoading,
    data: openedProjects,
    refetch: openedProjectsRefetch,
    error: errorOpenedProjects,
  } = useQuery(
    `${QUERIES.DASHBOARD_OPENED_PROJECTS}`,
    () => {
      return getOpenedProjects()
    },
    {
      cacheTime: 0,
      onError: (err) => {
        console.error(err)
      },
      refetchOnWindowFocus: false,
    }
  )

  const [data, setData] = useState<RecentActivities[]>([])

  const {
    isLoading: isRecentActivitiesLoading,
    data: recentActivities,
    refetch: recentActivitiesRefetch,
    error: errorRecentActivities,
  } = useQuery(
    `${QUERIES.DASHBOARD_RECENT_ACTIVITIES}`,
    () => {
      return getRecentActivities()
    },
    {
      cacheTime: 0,
      onSuccess(response) {
        setData(response.data)
      },
      onError: (err) => {
        console.error(err)
      },
      refetchOnWindowFocus: false,
    }
  )

  const {
    isLoading: isTimeTrackingLoading,
    data: dataConst,
    refetch: recentTimeTracking,
  } = useQuery(
    [`${QUERIES.TEAMS_LIST}-timetracking-dashboard-adm`],
    () => {
      if (currentFilter === '0') {
        return getTimeTrackingBySquadRandom()
      } else if (currentFilter === '1') {
        return getTimeTrackingBySquadRandomMonth()
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  )

  const [showModal, setShowModal] = useState<boolean>(false)
  const { setItemIdForUpdate } = useListView()
  const [taskId, setTaskId] = useState<ID>()
  const openEditModal = (taskId: ID) => {
    setItemIdForUpdate(taskId!)
    setTaskId(taskId)
    setShowModal(true)
  }

  const [showMore, setShowMore] = useState(false)

  const toolbar = {
    left: 'prevYear,prev,next,nextYear today',
    center: 'title',
    right: 'dayGridMonth,dayGridWeek,dayGridDay',
  }
  let eventGuid = 0
  const createEventId = () => String(eventGuid++)

  const handleDateSelect = useCallback((selectInfo: DateSelectArg) => {
    let title = prompt('Insira o título do evento')?.trim()
    let calendarApi = selectInfo.view.calendar
    calendarApi.unselect()
    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
      })
    }
  }, [])
  const { currentUser } = useAuth()
  const modules = currentUser?.instance?.plan?.modules
  const [showProjectModalStepper, setProjectShowModalStepper] = useState<boolean>(false)
  const [showTaskModalStepper, setTaskShowModalStepper] = useState<boolean>(false)

  const formatUserBirthdayDate = (date: string) => {
    const originalDate = new Date(date)

    const formatedDate =
      originalDate.getUTCDate().toString().padStart(2, '0') +
      '/' +
      (originalDate.getUTCMonth() + 1).toString().padStart(2, '0')

    return formatedDate
  }

  console.log(openedProjects)

  return (
    <>
      {/* START HEADER */}
      <div className='container'>
        <div className='row align-items-end justify-content-between bg-body pt-10 pb-15 px-3 px-md-5 mb-6 rounded'>
          <div className='col-md-8'>
            <div className='d-flex align-items-center mb-4'>
              <div className='fw-semi-bold fs-2 me-4'>
                {intl.formatMessage({ id: 'DASHBOARD.LABEL.WORKPLACE' })}
              </div>
              <span className='text-gray-600'>
                {intl.formatMessage({ id: 'DASHBOARD.LABEL.FEATURED_OF_THE_DAY' })}
              </span>
            </div>
            <div className='d-flex align-items-center'>
              <div className='symbol symbol-circle symbol-60px overflow-hidden me-3'>
                <div className='symbol symbol-60px symbol-md-60px'>
                  {currentUser?.avatar && TemaAtual === 'dark' ? (
                    <img
                      className='object-fit-cover'
                      alt={currentUser.name}
                      src={
                        isNotEmpty(currentUser.avatar) && currentUser.avatar !== 'null'
                          ? process.env.REACT_APP_THEME_API_URL + '/storage/' + currentUser?.avatar
                          : toAbsoluteUrl('/media/svg/files/blank-image-dark.svg')
                      }
                    />
                  ) : (
                    <img
                      className='object-fit-cover'
                      alt={currentUser?.name}
                      src={
                        isNotEmpty(currentUser?.avatar) && currentUser?.avatar !== 'null'
                          ? process.env.REACT_APP_THEME_API_URL + '/storage/' + currentUser?.avatar
                          : toAbsoluteUrl('/media/svg/files/blank-image.svg')
                      }
                    />
                  )}
                </div>
              </div>
              <div className='d-flex flex-column'>
                <p className='text-gray-900 fw-bold fs-4 mb-1'>
                  {today.getHours() < 12
                    ? intl.formatMessage({ id: 'GENERAL.GOOD_MORNING' })
                    : today.getHours() < 18
                      ? intl.formatMessage({ id: 'GENERAL.GOOD_AFTERNOON' })
                      : intl.formatMessage({ id: 'GENERAL.GOOD_EVENING' })}
                  , {currentUser?.name}!
                </p>
                {/* <span className='text-gray-600'>
                  Hoje inicia o ciclo dos clientes ABC e XYZ. Em 07 dias, será a entrega do layout
                  do site ABR.
                </span> */}
              </div>
            </div>
          </div>
          <div className='col-md-4 d-flex align-items-center justify-content-between justify-content-md-end mt-10 mt-md-0'>
            <div className='text-end border-end border-gray-300 pe-5 me-10'>
              <span className='text-gray-600'>
                {intl.formatMessage({ id: 'DASHBOARD.LABEL.REGISTERED_PROJECTS' })}
              </span>
              <div className='fs-2'>{dashboardInfo?.projects_quantity ?? 0}</div>
            </div>
            <div className='text-end'>
              <span className='text-gray-600'>
                {intl.formatMessage({ id: 'DASHBOARD.LABEL.TASKS_FOR_ME' })}
              </span>
              <div className='fs-2'>{dashboardInfo?.user_tasks_quantity ?? 0}</div>
            </div>
          </div>
        </div>
      </div>
      {/* END HEADER */}
      <div className='container pt-8 pb-15'>
        <div className='row mb-10'>
          <div className='col-12'>
            <div className='fs-2x fw-bold'>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</div>
          </div>
        </div>
        <div className='row bg-body pt-10 pb-4 px-3 px-md-5 mb-6 rounded'>
          <div className='col-md-12 col-lg-4 pe-md-10 pb-8 pb-md-0 mt-md-10 mt-lg-0 mb-8 mb-md-0 border-end-md border-bottom border-bottom-md-0'>
            <div className='fs-2 fw-bold'>
              {intl.formatMessage({ id: 'DASHBOARD.LABEL.GLOBAL_PROFIT_MARGIN' })}
            </div>
            {/* <select
              style={{
                backgroundImage: `url('data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23009ef7' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e')`,
              }}
              className='form-select form-select-sm w-80px text-primary bg-transparent border-0 p-0'
              id='period'
            >
              <option value='0'>
                Este ano
              </option>
              <option value='1'>2022</option>
              <option value='2'>2021</option>
              <option value='3'>2020</option>
            </select> */}
            <div className='mt-md-10 mt-lg-12'>
              <ChartsWidget9
                chartColor='primary'
                percentage={
                  dashboardInfo?.global_profit_margin
                    ? Math.floor(dashboardInfo?.global_profit_margin!)
                    : 0
                }
              />
            </div>
          </div>
          <div className='col-md-6 col-lg-4 ps-lg-10 pe-md-10 pb-8 pb-md-0 mt-md-10 mt-lg-0 mb-8 mb-md-0 border-end-md border-bottom border-bottom-md-0'>
            <div className='fs-2 fw-bold mb-8'>
              {intl.formatMessage({ id: 'DASHBOARD.LABEL.COMPLETED_PROJECTS' })}
            </div>
            <div className='mt-md-10 mt-lg-12'>
              <ChartsWidget14
                chartColor='success'
                percentage={
                  dashboardInfo?.closed_projects_percentage
                    ? Math.floor(dashboardInfo?.closed_projects_percentage!)
                    : 0
                }
              />
            </div>
          </div>
          <div className='col-md-6 col-lg-4 ps-md-10 mt-md-10 mt-lg-0'>
            <div className='fs-2 fw-bold mb-8 d-flex justify-content-between'>
              {intl.formatMessage({ id: 'DASHBOARD.LABEL.OVERBUDGET_PROJECTS' })}
              <Link to="/reports/overbudget-projects">
                <button className='btn btn-outline btn-outline-primary btn-sm' >
                  <i className="bi bi-graph-down ml-5"></i>
                </button>
              </Link>
            </div>
            <div className='mt-md-10 mt-lg-12'>
              <ChartsWidget14
                chartColor='danger'
                percentage={dashboardInfo?.over_budget_projects ?? 0}
              />
            </div>
          </div>
        </div>
        {/* <div className='row bg-body pt-10 pb-4 px-3 px-md-5 mb-6 rounded'>
          <div className='fs-2 fw-bold mb-8'>
            {intl.formatMessage({id: 'DASHBOARD.LABEL.MYSCHEDULE'})}
          </div>
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView='dayGridWeek'
            locale={ptbrLocale}
            selectable={true}
            select={handleDateSelect}
            editable={true}
            dayMaxEvents={true}
            headerToolbar={toolbar}
            height='auto'
            themeSystem='bootstrap5'
            events={[
              {
                title: 'The Title',
                start: '2023-01-13',
              },
              {
                title: 'Other Title',
                start: '2023-01-10',
              },
            ]}
          />
        </div> */}
        {dashboardProjects?.projects.length != 0 && (
          <div className='row g-6 g-xl-9 pt-8 mb-15'>
            <div className='fs-2 fw-bold'>
              {intl.formatMessage({ id: 'DASHBOARD.LABEL.PROJECTS_I_LEAD' })}
            </div>
            {dashboardProjects?.projects.map((project, i) => (
              <div key={i} className='col-md-6 col-lg-4'>
                <ProjectCard
                  id={parseInt(project.id!)}
                  name={project.name}
                  client={project.clientName}
                  budgetedHours={project.budgeted_hours}
                  users={project.users!}
                  begin_date={project.begin_date}
                  end_date={project.end_date}
                  closed_at={project.closed_at}
                  totalTime={project.totalTime}
                  profit_margin={project.profit_margin}
                />
              </div>
            ))}
          </div>
        )}
        {currentUser?.instance?.plan?.max_users_number != 1 && (
          <div className='row bg-body py-10 px-3 px-md-5 mb-6 rounded'>
            <div className='col-lg-6 pb-10 pb-lg-0 mb-10 mb-lg-0 border-bottom border-bottom-lg-0'>
              <div className='fs-2 fw-bold mb-8'>
                {intl.formatMessage({ id: 'DASHBOARD.LABEL.HR_HIGHLIGHTS' })}
              </div>
              <div className='content'>
                <div
                  className={`resume overflow-hidden transition-all ${showMore ? 'mh-300px overflow-scroll' : 'mh-210px'
                    }`}
                >
                  {dashboardInfo?.hr_highlights.map((user, i) => (
                    <div key={i} className='py-5 border-gray-200 d-flex align-items-center'>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-40px symbol-circle'>
                          <img
                            className='object-fit-cover'
                            alt={user.name}
                            src={
                              user.avatar
                                ? process.env.REACT_APP_THEME_API_URL + '/storage/' + user?.avatar
                                : toAbsoluteUrl(
                                  mode === 'light'
                                    ? '/media/svg/files/blank-image.svg'
                                    : '/media/svg/files/blank-image-dark.svg'
                                )
                            }
                          />
                        </div>

                        <div className='ms-4'>
                          <a className='fs-6 fw-bold text-gray-900 text-hover-primary mb-2'>
                            {user.name}
                          </a>
                          <div className='fw-semibold fs-7 text-muted'>
                            {user.type === 'aniversario_nascimento' &&
                              'Aniversário - ' + formatUserBirthdayDate(user.date)}
                            {user.type === 'aniversario_empresa' &&
                              `Em ${formatUserBirthdayDate(user.date)} faz ${diffInYears(
                                user.date,
                                today.toString()
                              )} ${diffInYears(user.date, today.toString()) === 1 ? 'ano' : 'anos'
                              } de empresa`}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {dashboardInfo?.hr_highlights?.length! > 3 && (
                  <div className='read-more text-center text-gray-200 mt-4'>
                    <span
                      className='text-primary cursor-pointer'
                      onClick={() => {
                        setShowMore(!showMore)
                      }}
                    >
                      {showMore
                        ? intl.formatMessage({ id: 'GENERAL.SEE_LESS' })
                        : intl.formatMessage({ id: 'GENERAL.SEE_MORE' })}
                      <i className='bi bi-chevron-expand text-primary'></i>
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='card card-flush '>
                <div className='card-header d-flex align-items-between p-1 justify-content-between'>
                  <h3 className='card-title flex-column'>
                    <span className='fs-2 fw-bold'>
                      {intl.formatMessage({ id: 'DASHBOARD.LABEL.TEAM_REGISTERED_HOURS' })} -{' '}
                      {dataConst?.timeTrackingSquadRandomAdm[0]?.team_name}
                    </span>
                    <span className='text-gray-400 mt-1 fw-semibold fs-6'>Últimos 7 dias</span>
                  </h3>
                  {/* <div className='card-toolbar'>
                  <label className='label-form me-3 fs-5'>
                    {intl.formatMessage({id: 'PROJECT.LABEL.PERIOD'})}
                  </label>
                  <select className='form-select form-select-sm'>
                  <option selected={currentFilter === '0' } value='0'>Últimos 7 dias</option>
                  <option selected={currentFilter === '1' } value='1'>Últimos 30 dias</option>
                  </select>
                </div> */}
                </div>
              </div>
              <div className='card-body pt-6'>
                <div className='tab-content'>
                  <div
                    className='tab-pane fade show active'
                    id='kt_stats_widget_16_tab_1'
                    role='tabpanel'
                    aria-labelledby='#kt_stats_widget_16_tab_link_1'
                  >
                    <div className='table-responsive'>
                      <table className='table table-row-dashed align-middle gs-0 gy-3 my-0'>
                        <thead>
                          <tr className='fs-7 fw-bold text-gray-400 border-bottom-0'>
                            <th className='p-0 pb-3 min-w-150px text-start'>MEMBRO</th>
                            <th className='p-0 pb-3 min-w-100px text-end pe-13 text-center'>
                              ÍNDICE DE LANÇAMENTO
                            </th>
                            <th className='p-0 pb-3 w-125px text-end pe-7'>ALOCAÇÃO</th>
                          </tr>
                        </thead>

                        <tbody>
                          {dataConst?.timeTrackingSquadRandomAdm.length === 0 ? (
                            <tr>
                              <td colSpan={3} className="text-center">
                                <span className='fs-3 fw-bold mb-0'>
                                  Nenhum apontamento realizado até o momento...
                                </span>
                              </td>
                            </tr>
                          ) : (
                            dataConst?.timeTrackingSquadRandomAdm.map((row, i) => (
                              <tr key={row.id}>
                                <td>
                                  <div className='d-flex align-items-center'>
                                    <div className='symbol symbol-circle symbol-40px overflow-hidden me-3'>
                                      {currentUser?.avatar && TemaAtual === 'dark' ? (
                                        <img
                                          alt={row.name}
                                          className='object-fit-cover'
                                          src={
                                            isNotEmpty(row.avatar) && row.avatar !== 'null'
                                              ? process.env.REACT_APP_THEME_API_URL +
                                              '/storage/' +
                                              row?.avatar
                                              : toAbsoluteUrl(
                                                '/media/svg/files/blank-image-dark.svg'
                                              )
                                          }
                                        />
                                      ) : (
                                        <img
                                          alt='Logo'
                                          src={
                                            isNotEmpty(row?.avatar) && row?.avatar !== 'null'
                                              ? process.env.REACT_APP_THEME_API_URL +
                                              '/storage/' +
                                              row?.avatar
                                              : toAbsoluteUrl('/media/svg/files/blank-image.svg')
                                          }
                                        />
                                      )}
                                    </div>
                                    <div className='d-flex justify-content-start flex-column'>
                                      <a
                                        href=''
                                        className='text-gray-800 fw-bold text-hover-primary mb-1 fs-6'
                                      >
                                        {row.name}
                                      </a>
                                      <span className='text-gray-400 fw-semibold d-block fs-7'>
                                        {row.job_role}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td className='text-end pe-13 text-center'>
                                  <span
                                    className={`fw-bold fs-6 text-center ${(row!.performance! >=
                                      row.instanceConfiguration.min_hour_index &&
                                      row!.performance! <=
                                      row.instanceConfiguration.max_hour_index) ||
                                      !row.has_time_tracking
                                      ? 'text-success'
                                      : row!.performance! <
                                        row.instanceConfiguration.min_hour_index
                                        ? 'text-danger'
                                        : 'text-warning'
                                      }`}
                                  >
                                    {isNotEmpty(row.performance) ? row.performance + '%' : '-'}
                                  </span>
                                </td>
                                <td className='text-end pe-0 text-center'>
                                  <span className='text-gray-600 fw-bold fs-6 text-center'>
                                    <span
                                      className={`${(row!.performance! >=
                                        row.instanceConfiguration.min_hour_index &&
                                        row!.performance! <=
                                        row.instanceConfiguration.max_hour_index) ||
                                        !row.has_time_tracking
                                        ? 'text-success'
                                        : row!.performance! <
                                          row.instanceConfiguration.min_hour_index
                                          ? 'text-danger'
                                          : 'text-warning'
                                        }`}
                                    >
                                      {row?.hours_tracked}h
                                    </span>{' '}
                                    de {secondsToHoursFormatNotSeconds(row?.hours_total!)}h
                                  </span>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className='row align-items-end bg-body pt-10 px-3 px-md-5 mb-6 rounded'>
          <div className='d-flex justify-content-between align-items-center mb-20'>
            <div className='fs-2 fw-bold'>
              {intl.formatMessage({ id: 'DASHBOARD.LABEL.RECURRING_PROJECTS' })}
            </div>
            {/* <div className='d-flex align-items-center pe-md-0'>
              <label className='label-form me-3 fs-5'>
                {intl.formatMessage({id: 'PROJECT.LABEL.PERIOD'})}
              </label>
              <select className='form-select form-select-sm'>
                <option>Ciclo atual</option>
                <option value='1'>12/12 a 16/12</option>
                <option value='2'>05/12 a 09/12</option>
                <option value='3'>28/11 a 02/12</option>
              </select>
            </div> */}
          </div>
          {recurringProjects?.projects.length == 0 ? (
            <div className='d-flex flex-column justify-content-center'>
              <div className='mb-10'>
                <div className='fs-2 text-gray-800 text-center mb-6'>
                  <p className='me-2'>
                    Você ainda não tem projetos recorrentes. Crie seus primeiros para acompanhar com
                    facilidade!
                  </p>
                </div>

                <div className='text-center'>
                  <button
                    onClick={() => setProjectShowModalStepper(true)}
                    className='btn btn-primary'
                  >
                    Novo projeto
                  </button>
                </div>
              </div>
            </div>
          ) : (
            recurringProjects?.projects.map((project, i) => (
              <div key={i} className='col-6 col-md-3 col-lg-2 mx-md-6 mb-20 text-center'>
                <Link
                  to={`/clients/${project.client_id}`}
                  className='d-block fs-4 fw-bold text-dark text-hover-primary'
                >
                  [{project.clientName}]
                </Link>
                <Link
                  to={`/projects/${project.id}`}
                  className='d-block fs-5 fw-bold text-dark text-hover-primary'
                >
                  {project.name}
                </Link>
                <ChartsWidget9
                  chartColor={project.total_time_percentage! < 100 ? 'primary' : 'danger'}
                  percentage={project.total_time_percentage!}
                  chartHeight='160px'
                />
                <div className='fw-semi-bold'>Ciclo: {project.cycle}</div>
              </div>
            ))
          )}
        </div>
        <div className='row justify-content-between bg-body py-10 px-3 px-md-5 mb-6 rounded'>
          <div className='col-lg-6 pb-10 pb-lg-0 mb-15 mb-lg-0 border-bottom border-bottom-lg-0'>
            <div className='fs-2 fw-bold mb-8'>
              {intl.formatMessage({ id: 'DASHBOARD.LABEL.PROGRESS_OPEN_PROJECTS' })}
            </div>
            <div className='mh-350px overflow-x-hidden overflow-y-scroll'>
              {openedProjects?.projects.length == 0 ? (
                <div className='d-flex flex-column justify-content-center'>
                  <div className='mb-10'>
                    <div className='fs-4 text-gray-800 text-center mb-6'>
                      <p className='me-2'>
                        Sem projetos em aberto no momento. Inicie um novo projeto para acompanhar o
                        progresso!
                      </p>
                    </div>

                    <div className='text-center'>
                      <button
                        onClick={() => setProjectShowModalStepper(true)}
                        className='btn btn-primary'
                      >
                        Novo projeto
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                openedProjects?.projects.sort((a, b) => (b.used_time || 0) - (a.used_time || 0)).map((openProject, i) => (
                  <div key={i} className='mb-3'>
                    <div className="d-flex flex-column">
                      <Link to={`/clients/${openProject.client_id}`} target='_blank' className='text-dark'>
                        {openProject.clientName}
                      </Link>
                      <Link to={`/projects/${openProject.id}`} target='_blank' className='text-dark'>
                        {openProject.name}
                      </Link>

                    </div>

                    <div className='d-flex align-items-center'>
                      <div className='col-9'>
                        <div
                          className='h-7px w-100 bg-light overflow-hidden rounded'
                          data-bs-toggle='tooltip'
                        >
                          <div
                            className={clsx(
                              'rounded h-7px',
                              openProject.used_time! >= 100
                                ? 'bg-danger'
                                : openProject.used_time! > 80
                                  ? 'bg-success'
                                  : 'bg-primary'
                            )}
                            role='progressbar'
                            style={{ width: `${openProject.used_time}%` }}
                          ></div>
                        </div>
                      </div>
                      <div className='col-3 text-start ps-2'>{`${Math.round(
                        openProject.used_time!
                      )}%`}</div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='fs-2 fw-bold mb-8'>
              {intl.formatMessage({ id: 'DASHBOARD.LABEL.RELEVANT_ACTIVITIES' })}
            </div>
            {data?.length == 0 ? (
              <div className='d-flex flex-column justify-content-center'>
                <div className='mb-10'>
                  <div className='fs-4 text-gray-800 text-center mb-6'>
                    <p className='me-2'>
                      Nenhuma atividade por aqui ainda. Comece a registrar suas tarefas e
                      mantenha-se organizado!
                    </p>
                  </div>

                  <div className='text-center'>
                    <button
                      onClick={() => setTaskShowModalStepper(true)}
                      className='btn btn-primary'
                    >
                      Nova tarefa
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className='border border-gray-200 py-2 px-5 h-350px overflow-x-hidden overflow-y-scroll'>
                {data?.map((recentActivity, index) => (
                  <div
                    key={index}
                    className='py-5 border-bottom border-gray-200 d-flex align-items-center'
                  >
                    <div className='symbol symbol-circle symbol-30px me-5'>
                      {recentActivity.userAvatar && TemaAtual === 'dark' ? (
                        <img
                          alt={recentActivity.userName}
                          className='object-fit-cover'
                          src={
                            isNotEmpty(recentActivity.userAvatar) &&
                              recentActivity.userAvatar !== 'null'
                              ? process.env.REACT_APP_THEME_API_URL +
                              '/storage/' +
                              recentActivity.userAvatar
                              : toAbsoluteUrl('/media/svg/files/blank-image-dark.svg')
                          }
                        />
                      ) : (
                        <img
                          alt='Logo'
                          src={
                            isNotEmpty(recentActivity.userAvatar) &&
                              recentActivity.userAvatar !== 'null'
                              ? process.env.REACT_APP_THEME_API_URL +
                              '/storage/' +
                              recentActivity.userAvatar
                              : toAbsoluteUrl('/media/svg/files/blank-image.svg')
                          }
                        />
                      )}
                    </div>
                    <div>
                      {recentActivity.task_id ? (
                        <>
                          {recentActivity.userName} -{' '}
                          <span className='text-gray fw-bold'>
                            {' '}
                            {recentActivity.description} - {recentActivity.created_at}{' '}
                          </span>
                          <span
                            className='text-primary cursor-pointer'
                            onClick={(e) => {
                              const newParams = new URLSearchParams(searchParams)
                              newParams.set('taskId', String(recentActivity.task_id))
                              setSearchParams(newParams)
                            }}
                          >
                            {' '}
                            #{recentActivity.task_id}
                          </span>
                        </>
                      ) : (
                        <>
                          {recentActivity.userName} -{' '}
                          <span className='text-gray fw-bold'> {recentActivity.description}</span>
                          <span className='text-lowercase'>
                            {' '}
                            {intl.formatMessage({ id: 'GENERAL.THE_PROJECT' })}{' '}
                          </span>
                          <Link to={`/projects/${recentActivity.project_id}`}>
                            {' '}
                            {recentActivity.project_name}
                          </Link>
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className='row justify-content-between bg-body py-10 px-3 px-md-5 mb-6 rounded'>
          <div className='col-12'>
            <div className='fs-2 fw-bold mb-8'>
              {intl.formatMessage({ id: 'DASHBOARD.LABEL.EXPIRING_CONTRACTS' })}
            </div>
            {modules?.some(
              (module) => module.slug_name == 'gestao-de-contratos' && module.pivot.active
            ) ? (
              !isLoading && contractsData.length === 0 ? (
                <ContractsPlaceholder />
              ) : (
                <div className='table-responsive'>
                  <table
                    id='kt_file_manager_list'
                    data-kt-filemanager-table='folders'
                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    style={{ marginLeft: '0px', width: '662px' }}
                    {...getTableProps()}
                  >
                    <thead>
                      <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                        {headers.map((column: ColumnInstance<Contracts>) => (
                          <CustomHeaderColumn key={column.id} column={column} />
                        ))}
                      </tr>
                    </thead>
                    <tbody className='fw-semibold text-gray-600' {...getTableBodyProps()}>
                      {rows.length > 0 ? (
                        rows.map((row: Row<Contracts>, i) => {
                          prepareRow(row)

                          return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                        })
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                              {intl.formatMessage({ id: 'TABLE.GENERAL.NO_RECORD_FOUND' })}
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {isLoading && <Loading />}
                </div>
              )
            ) : (
              <div className='d-flex justify-content-center'>
                <UpgradePlanWidget />
              </div>
            )}
          </div>
        </div>
      </div>
      <ProjectCreateModalStepper
        show={showProjectModalStepper}
        handleClose={() => setProjectShowModalStepper(false)}
      />
      <TaskModal show={showTaskModalStepper} handleClose={() => setTaskShowModalStepper(false)} />
    </>
  )
}

export { DashboardManagerPage }
