import {useParams} from 'react-router-dom'
import {Step1} from './Step1'

const StepsUserWrapper = () => {
  const {step} = useParams()

  const stepsList = [Step1]
  let CurrentStep = Step1

  if (step) {
    CurrentStep = stepsList[parseInt(step) - 1]
  }

  return <CurrentStep />
}

export {StepsUserWrapper}
