import {FC} from 'react'
import {ClientEditModalForm} from '../../../../../_metronic/partials/modals/client-edit-modal/ClientEditModalForm'
import {initialClient} from '../../../../modules/clients/components/core/_models'

const Step3: FC = () => {
  return (
    <div className='w-100'>
      {/* <!--begin::Heading--> */}
      <div className='pb-10 pb-lg-12'>
        {/* <!--begin::Title--> */}
        <h2 className='fw-bold text-dark'>Cadastrando meu primeiro cliente</h2>
        {/* <!--end::Title--> */}
        {/* <!--begin::Notice--> */}
        <div className='text-gray-700 fw-semibold fs-5'>
          Um projeto está sempre ligado a um cliente. Caso você não tenha um cliente e deseja
          trabalhar em um projeto independente, você pode cadastrar com o nome da sua empresa.{' '}
          {/* <a href='#' className='link-primary fw-bold'>
            Se tiver dúvidas, veja nossa área de ajuda
          </a>
          . */}
        </div>
        {/* <!--end::Notice--> */}
      </div>
      {/* <!--end::Heading--> */}
      <div className='fv-row'>
        <div className='row'>
          <div className='col-12 bg-body px-10 py-5'>
            <ClientEditModalForm isClientLoading={false} client={initialClient} />
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step3}
