//@ts-nocheck
import React, {
  FC,
  forwardRef,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import Swal from 'sweetalert2'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {Link, useSearchParams} from 'react-router-dom'
import {Dropdown} from 'react-bootstrap'
import {ID, isNotEmpty, KTSVG, QUERIES, TaskSprint, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Task, ResponseTask} from '../core/_models'
import {useListView} from '../core/ListViewProvider'
import {Loading} from '../../../../../../_metronic/partials/components/loading/Loading'
import {
  deleteTaskInSprint,
  playTimeTracking,
  stopTimeTracking,
  updateTask,
  getUsersByTaskId,
  updateStatusTask,
} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {getProjectsTask} from '../../core/_requests'
import {Project} from '../../core/_models'
import {useAuth, UserModel} from '../../../../auth'
import {Toast} from '../../../../../../_metronic/partials/widgets'
import {CustomToggleProps} from '../table/columns/TaskActionsCell'
import {deleteTask} from '../core/_requests'
import {deliveryTask} from '../core/_requests'
import {OverviewAttachement} from '../TaskAttachment/OverviewAttachment'
import {UsersSymbolGroupColumn} from '../UsersSymbolGroupColumn'
import {AdjustTimeUserModal} from '../../../../../../_metronic/partials/modals/adjust-time-user/AdjustTimeUserModal'
import {SelectAutoComplete} from '../../../../../../_metronic/partials/components/Select/SelectAutoComplete'
import {CreateProjectAndClient} from '../../../../../../_metronic/partials/modals/create-project-and-client/CreateProjectAndClientModal'
import {getChecklistClone} from '../TaskChecklist/core/_requests'
import {TaskCloneModal} from '../task-clone-modal/TaskCloneModal'

type Props = {
  isTaskLoading: boolean
  task: ResponseTask
  handleClose: () => void | undefined
}
interface responsibeProps extends InputHTMLAttributes<HTMLInputElement> {
  image: string
  name: string
}

interface checkProps extends InputHTMLAttributes<HTMLInputElement> {
  content: string
  check: boolean
}

const TaskEditModalForm: FC<Props> = ({task, isTaskLoading, handleClose}) => {
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [data, setData] = useState<Task>(task.tasks)
  const queryClient = useQueryClient()

  const [responsible, setResponsible] = useState<Array<responsibeProps>>([])
  const {setCurrentUser} = useAuth()
  const [isSubmitting, setSubmitting] = useState<boolean>(false)
  const [clients, setClients] = useState<Project[]>([])
  var today = new Date().toISOString().split('T')[0]

  const [statustask, setStatusTask] = useState(task.tasks.status)
  const [time, setTime] = useState(task.tasks.total_time!)
  const [play, setPlay] = useState(task.tasks.play)
  const countRef = useRef()
  const [stopwatch, setStopwatch] = useState(0)

  const [showAdjustTimeModal, setShowAdjustTimeModal] = useState<boolean>(false)
  const [showProjectAndClientModal, setShowProjectAndClientModal] = useState<boolean>(false)
  const [tasks, setTasks] = useState<Array<TaskSprint>>([])
  const TemaAtual = window.localStorage.getItem('kt_theme_mode_value') || ''
  const [checks, setChecks] = useState<Array<checkProps>>([])
  const [name_input, setNameInput] = useState<string>(data.name?.toString()!)
  const [description_input, setDescriptionInput] = useState<string>(data.description?.toString()!)
  const [delivery_date_input, setDeliveryDateInput] = useState<string>(
    data.expected_date?.toString()!
  )
  const [searchParams, setSearchParams] = useSearchParams()
  const sprintId = searchParams.get('sprintId')
  const [showModalClone, setShowModalClone] = useState<boolean>(false)

  const getSelectClients = useMutation(() => getProjectsTask(parseInt(task.tasks.project_id!)), {
    onSuccess: (response) => {
      setClients(response.data)
    },
  })

  useEffect(() => {
    getSelectClients.mutateAsync()
    getChecklistCost.mutateAsync()
  }, [])

  useEffect(() => {
    if (play === 'run') {
      let intervalId: NodeJS.Timer
      const newTime = task.tasks.total_time > time ? task.tasks.total_time : time + 1
      intervalId = setInterval(() => setTime(newTime), 1000)
      return () => clearInterval(intervalId)
    } else {
      setStopwatch(time)
    }

    return () => clearInterval(countRef.current)
  }, [play, time, task.tasks.total_time])

  const updateData = (fieldsToUpdate: Partial<Task>) => {
    const updatedData = {...data, ...fieldsToUpdate}
    setData(updatedData)
  }

  const addResponsible = (user: string) => {
    setResponsible([...responsible, {image: '', name: user}])
  }

  const getChecklistCost = useMutation(() => getChecklistClone(task.tasks.id), {
    onSuccess: (response) => {
      setChecks(response.data)
    },
  })

  const addCheck = () => {
    setChecks([...checks, {content: '', check: false}])
  }

  const removeCheck = (key: number) => {
    setChecks(checks.filter((value, index) => key !== index))
  }

  const closeModal = () => {
    clearQueryParams()
    handleClose()
  }

  function clearQueryParams() {
    const paramName = 'taskId'
    const url = new URL(window.location.href)
    url.searchParams.delete(paramName)
    const newUrl = url.search ? url.href : url.href.replace('?', '')
    window.history.replaceState({}, document.title, newUrl)
  }

  const {
    isLoading,
    data: dataUsers,
    error,
  } = useQuery(
    `${QUERIES.TASKS_LIST}-${task.tasks.id}-users`,
    () => {
      return getUsersByTaskId(task.tasks.id)
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  )

  const removeResponsible = (key: number) => {
    setResponsible(responsible.filter((value, index) => key !== index))
  }

  const deleta = async () => {
    const CurrentTheme = window.localStorage.getItem('kt_theme_mode_value') || ''
    let tema = ''
    if (CurrentTheme === 'dark') {
      tema = '#353b48'
    } else {
      tema = '#fff'
    }

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false,
    })

    swalWithBootstrapButtons
      .fire({
        title: 'Você tem certeza?',
        html: 'Tem certeza que deseja deletar essa tarefa? Caso tenha horas lançadas para ela, isso poderá afetar o seu time tracking e os custos do projeto.',
        icon: 'warning',
        background: tema,
        showCancelButton: true,
        confirmButtonText: 'Sim, pode excluir',
        cancelButtonText: 'Não, cancele!',
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          return await deleteItem.mutateAsync()
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Toast.fire({
            icon: 'success',
            title: intl.formatMessage(
              {id: 'ALERT.LABEL.SUCCESS_TASK_UPDATE_ITEM'},
              {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.TASK'})}
            ),
            customClass: {
              popup: 'bg-light-success',
              title: 'text-success',
            },
          })
        }
      })
  }

  const deletaTaskInSprint = async () => {
    const CurrentTheme = window.localStorage.getItem('kt_theme_mode_value') || ''
    let tema = ''
    if (CurrentTheme === 'dark') {
      tema = '#353b48'
    } else {
      tema = '#fff'
    }

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false,
    })

    swalWithBootstrapButtons
      .fire({
        title: 'Você tem certeza?',
        html: intl.formatMessage({id: 'ALERT.LABEL.IT_CAN_NOT_BE_UNDONE'}),
        icon: 'warning',
        background: tema,
        showCancelButton: true,
        confirmButtonText: 'Sim, pode desvincular!',
        cancelButtonText: 'Não, cancele!',
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          return await deleteItemTaskInSprint.mutateAsync()
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Toast.fire({
            icon: 'success',
            title: intl.formatMessage(
              {id: 'ALERT.LABEL.SUCCESS_TASK_UPDATE_ITEM'},
              {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.TASK'})}
            ),
            customClass: {
              popup: 'bg-light-success',
              title: 'text-success',
            },
          })
        }
      })
  }

  const hours = Math.floor(time / 3600)
  const minutes = Math.floor((time % 3600) / 60)
  const seconds = time % 60

  const startAndStop = () => {
    if (play === 'pause') {
      playItem.mutateAsync()
    } else {
      stopItem.mutateAsync()
    }
  }

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
      queryClient.invalidateQueries()
    }
    setItemIdForUpdate(undefined)
  }

  const entregatask = async () => {
    const CurrentTheme = window.localStorage.getItem('kt_theme_mode_value') || ''
    let tema = ''
    if (CurrentTheme === 'dark') {
      tema = '#353b48'
    } else {
      tema = '#fff'
    }

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false,
    })

    swalWithBootstrapButtons
      .fire({
        title: intl.formatMessage({id: 'ALERT.TITLE.TASK.DELIVERY'}),
        icon: 'warning',
        background: tema,
        showCancelButton: true,
        confirmButtonText: intl.formatMessage({id: 'GENERAL.YES'}),
        cancelButtonText: intl.formatMessage({id: 'GENERAL.NOT'}),
        reverseButtons: true,
      })
      .then(async (result) => {
        clearQueryParams()
        if (result.isConfirmed) {
          return await deliveryItem.mutateAsync()
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          Toast.fire({
            icon: 'warning',
            title: intl.formatMessage(
              {id: 'ALERT.LABEL.ERROR_TASK_CLOSE_ITEM'},
              {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.TASK'})}
            ),
            customClass: {
              popup: 'bg-light-danger',
              title: 'text-danger',
            },
          })
          clearQueryParams()
        }
      })
  }

  const deliveryItem = useMutation(() => deliveryTask(task.tasks.id), {
    onSuccess: (response) => {
      Toast.fire({
        icon: response.status === 'success' ? 'success' : 'error',
        title: response.message,
        customClass: {
          popup: response.status === 'success' ? 'bg-light-success' : 'bg-light-error',
          title: TemaAtual === 'dark' ? 'text-dark' : 'text-success',
        },
      })

      if (play === 'run')
        setCurrentUser((user) => ({...user, has_active_time_tracking: false} as UserModel))

      closeModal()
      queryClient.invalidateQueries()
    },
  })

  const playItem = useMutation(() => playTimeTracking(task.tasks.id), {
    onSuccess: (response) => {
      Toast.fire({
        icon: 'success',
        title: intl.formatMessage(
          {id: 'ALERT.LABEL.SUCCESS_TIMETRACKING_START'},
          {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.TIMETRACKING'})}
        ),
        customClass: {
          popup: 'bg-light-success',
          title: 'text-success',
        },
      })
      queryClient.invalidateQueries(`${QUERIES.TASKS_LIST}-task-${task.tasks.id}`)
      queryClient.invalidateQueries(`${QUERIES.TASKS_LIST}-${task.tasks.id}-users`)
      queryClient.invalidateQueries(`${QUERIES.SPRINTS_LIST}-${sprintId!}`)
      setCurrentUser((user) => {
        return {...user, has_active_time_tracking: true} as UserModel
      })
      setPlay('run')
    },
  })

  const stopItem = useMutation(() => stopTimeTracking(task.tasks.id), {
    onSuccess: (response) => {
      Toast.fire({
        icon: 'success',
        title: intl.formatMessage(
          {id: 'ALERT.LABEL.SUCCESS_TIMETRACKING_STOP'},
          {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.TIMETRACKING'})}
        ),
        customClass: {
          popup: 'bg-light-success',
          title: 'text-success',
        },
      })

      queryClient.invalidateQueries(`${QUERIES.TASKS_LIST}-task-${task.tasks.id}`)
      queryClient.invalidateQueries(`${QUERIES.TASKS_LIST}-${task.tasks.id}-users`)
      setCurrentUser((user) => {
        return {...user, has_active_time_tracking: false} as UserModel
      })
      setPlay('pause')
    },
  })

  const deleteItem = useMutation(() => deleteTask(task.tasks.id), {
    onSuccess: (response) => {
      Toast.fire({
        icon: 'success',
        title: intl.formatMessage(
          {id: 'ALERT.LABEL.SUCCESS_TASK_DELETE_ITEM'},
          {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.TASK'})}
        ),
        customClass: {
          popup: 'bg-light-success',
          title: 'text-success',
        },
      })
      queryClient.invalidateQueries()
    },
  })

  const deleteItemTaskInSprint = useMutation(() => deleteTaskInSprint(task.tasks.id, sprintId), {
    onSuccess: (response) => {
      Toast.fire({
        icon: 'success',
        title: intl.formatMessage(
          {id: 'ALERT.LABEL.SUCCESS_TASK_UPDATE_ITEM'},
          {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.TASK'})}
        ),
        customClass: {
          popup: 'bg-light-success',
          title: 'text-success',
        },
      })
      cancel(true)
      queryClient.invalidateQueries()
    },
  })

  const initialValues = {
    id: task.tasks.id,
    file: [],
    name: task.tasks.name,
    description: task.tasks.description,
    expected_date: task.tasks.expected_date,
    project_id: task.tasks.project_id,
    checklist_concluded: [],
    checklist_name: [],
    users: [],
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      try {
        await updateTask(values).then((response) => {
          Toast.fire({
            icon: 'success',
            title: intl.formatMessage(
              {id: 'ALERT.LABEL.SUCCESS_TASK_UPDATE_ITEM'},
              {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.TASK'})}
            ),
            customClass: {
              popup: 'bg-light-success',
              title: 'text-success',
            },
          })
          cancel(true)
          queryClient.invalidateQueries()
          searchParams.delete('taskId')
          handleClose()
        })
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
        queryClient.invalidateQueries()
      }
    },
  })

  const submitForm = useCallback(
    async (e: any) => {
      formik.setFieldValue(
        'checklist_concluded',
        checks.map((check, index) => {
          return check.check
        })
      )

      formik.setFieldValue(
        'checklist_name',
        checks.map((check, index) => {
          return check.content
        })
      )

      formik.submitForm()
    },
    [formik]
  )

  useEffect(() => {
    formik.setFieldValue('task_id', data.id)
    formik.setFieldValue('status', data.status)
    formik.setFieldValue('name', name_input)
    formik.setFieldValue('description', description_input)
    formik.setFieldValue('expected_date', delivery_date_input)
    formik.setFieldValue('project_id', task.tasks.project_id)
    formik.setFieldValue('creator_id', task.tasks.creator_id)
  }, [name_input, description_input, delivery_date_input])

  const CustomToggle = forwardRef((props: CustomToggleProps, ref: React.Ref<HTMLAnchorElement>) => (
    <a
      href=''
      className='text-hover-primary'
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        props.onClick(e)
      }}
    >
      {props.children}
      <span style={{paddingLeft: '5px'}}></span>
    </a>
  ))

  const handleCreateProjectAndClient = (newProjectName: string) => {
    setSearchParams({new_project_name: newProjectName})
    setShowProjectAndClientModal(true)
  }

  const openTask = async () => {
    updateStatusTask(task.tasks.id, 'Backlog')

    Toast.fire({
      icon: 'success',
      title: intl.formatMessage(
        {id: 'ALERT.LABEL.SUCCESS_TASK_OPEN_ITEM'},
        {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.TASK'})}
      ),
      customClass: {
        popup: 'bg-light-success',
        title: 'text-success',
      },
    })
    queryClient.invalidateQueries()
    cancel(true)
  }

  const openCloneTask = () => {
    setItemIdForUpdate(task.tasks.id)
    setShowModalClone(true)
  }

  return (
    <>
      <div className='modal-content'>
        <form
          id='kt_modal_add_project_milestone_form'
          className='form'
          onSubmit={(e) => e.preventDefault()}
          noValidate
        >
          <div className='modal-header border-0'>
            <div className='col-md-6'>
              <input
                value={name_input}
                onChange={(e: any) => setNameInput(e.target.value)}
                placeholder={intl.formatMessage({id: 'FORM.INPUT.NAME.TASK_TITLE'})}
                type='text'
                name='name'
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault()
                }}
                className={clsx('form-control py-2 bg-transparent border-0 fs-1')}
                autoComplete='off'
              />
            </div>

            {/*< TIMETRACKING />*/}
            {statustask !== 'Entregue' && (
              <div className='col-md-1 d-none d-md-flex justify-content-end pe-4'>
                <div
                  className='symbol symbol-35px symbol-circle timetracking_button cursor-pointer '
                  onClick={startAndStop}
                >
                  {play === 'run' ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='35'
                      height='35'
                      fill='currentColor'
                      className='bi bi-pause-circle'
                      viewBox='0 0 16 16'
                      style={{color: '#50b840'}}
                    >
                      <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
                      <path d='M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z' />
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='35'
                      height='35'
                      fill='currentColor'
                      className='bi bi-play-circle'
                      viewBox='0 0 16 16'
                      style={{color: '#009ef7'}}
                    >
                      <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
                      <path d='M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z' />
                    </svg>
                  )}
                </div>
              </div>
            )}

            {statustask !== 'Entregue' && (
              <div
                className='symbol symbol-35px symbol-circle timetracking_button'
                onClick={() => entregatask()}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='35'
                  height='35'
                  fill='currentColor'
                  className='bi bi-check2-circle cursor-pointer '
                  viewBox='0 0 16 16'
                  style={{color: '#007dbb'}}
                >
                  <path d='M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z' />
                  <path d='M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z' />
                </svg>
              </div>
            )}

            <div className='col-md-1 d-none d-md-flex justify-content-end pe-4'>
              {play === 'run' ? (
                <p
                  className={clsx('bg-transparent fs-3')}
                  style={{color: '#50b840', marginTop: '15px', marginRight: '-15px'}}
                >
                  {hours}:{minutes.toString().padStart(2, '0')}:
                  {seconds.toString().padStart(2, '0')}
                </p>
              ) : (
                <p
                  className={clsx('bg-transparent fs-3')}
                  style={{color: '#009ef7', marginTop: '15px', marginRight: '-15px'}}
                >
                  {hours}:{minutes.toString().padStart(2, '0')}:
                  {seconds.toString().padStart(2, '0')}
                </p>
              )}
            </div>
            {/*< TIMETRACKING />*/}

            <div className='col-md-2 d-none d-md-flex justify-content-end pe-4'>
              <UsersSymbolGroupColumn
                users={dataUsers?.tasks?.users!}
                usersAll={dataUsers?.tasks?.usersAll!}
                taskId={data.id}
              />
            </div>
            <div className='col-md-1 d-flex justify-content-end align-items-center'>
              <div className='actions pt-1'>
                <Dropdown>
                  <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
                    <i className='bi bi-list-ul fs-1 text-dark'></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={(e) => {
                        e.preventDefault()
                        setShowAdjustTimeModal(true)
                      }}
                      eventKey='1'
                    >
                      {intl.formatMessage({id: 'TABLE.GENERAL.LABEL.ADJUST_TIMETRACKING'})}
                    </Dropdown.Item>
                    {statustask == 'Entregue' ? (
                      <Dropdown.Item onClick={openTask} eventKey='4'>
                        {intl.formatMessage({id: 'TABLE.GENERAL.LABEL.OPEN_TASK'})}
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item onClick={entregatask} eventKey='4'>
                        {intl.formatMessage({id: 'TABLE.GENERAL.LABEL.FINIS_TASK'})}
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item onClick={openCloneTask} eventKey='5'>
                      {intl.formatMessage({id: 'TABLE.GENERAL.LABEL.CLONE_TASK'})}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={deleta} eventKey='3'>
                      {intl.formatMessage({id: 'TABLE.GENERAL.LABEL.DELETE'})}
                    </Dropdown.Item>
                    {sprintId && (
                      <Dropdown.Item onClick={deletaTaskInSprint} eventKey='4'>
                        {intl.formatMessage({id: 'TABLE.GENERAL.LABEL.DELETE.TASK.SPRINT'})}
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                {/* end::Menu */}
              </div>
              <button
                className='btn btn-sm btn-icon btn-active-color-primary h-auto ms-4'
                onClick={closeModal}
              >
                <KTSVG
                  className='svg-icon-2x svg-icon-gray-800'
                  path='/media/icons/duotune/arrows/arr061.svg'
                />
              </button>
            </div>
          </div>
          <div className='modal-body d-flex flex-column flex-lg-row justify-content-between'>
            <div className='col-lg-8 pe-5 pb-5 pb-lg-0 mb-15 mb-lg-0 border-bottom border-bottom-lg-0 d-flex flex-column'>
              <ul
                className='nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap '
                role='tablist'
              >
                <li className='nav-item'>
                  <a
                    className='nav-link cursor-pointer text-gray-900 active'
                    data-bs-toggle='tab'
                    href='#kt_task_description_pane'
                  >
                    {intl.formatMessage({id: 'TASK.TABLE.DESCRIPTION'})}
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link cursor-pointer text-gray-900'
                    data-bs-toggle='tab'
                    href='#kt_task_attachments_pane'
                  >
                    {intl.formatMessage({id: 'TASK.TABLE.ATTACHMENTS'})}
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link cursor-pointer text-gray-900'
                    data-bs-toggle='tab'
                    href='#kt_task_checklist_pane'
                  >
                    {intl.formatMessage({id: 'TASK.TABLE.CHECKLIST'})}
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link cursor-pointer text-gray-900'
                    data-bs-toggle='tab'
                    href='#kt_task_comments_pane'
                  >
                    {intl.formatMessage({id: 'TASK.TABLE.COMMENTS'})}
                  </a>
                </li>
              </ul>
              <div className='tab-content pt-3 h-100'>
                <div id='kt_task_description_pane' className='tab-pane fade show active h-100'>
                  <textarea
                    className='form-control border-0 fw-normal h-100'
                    id='description'
                    name='description'
                    value={description_input}
                    onChange={(e: any) => setDescriptionInput(e.target.value)}
                    style={{resize: 'none'}}
                    rows={8}
                    placeholder={intl.formatMessage({id: 'TASK.TABLE.DESCRIPTION'})}
                  ></textarea>
                </div>

                <div id='kt_task_attachments_pane' className='tab-pane fade'>
                  <div className='pt-6'>
                    <OverviewAttachement taskId={task.tasks.id} />
                  </div>
                </div>

                <div id='kt_task_checklist_pane' className='tab-pane fade'>
                  <div className='checklist pt-6'>
                    {checks?.map((x, key) => {
                      return (
                        <div className='check d-flex align-items-center mb-4' key={key}>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id={key.toString()}
                            {...formik.getFieldProps('checklist_concluded')}
                            name='checklist_concluded'
                            checked={x.check}
                            defaultChecked={x.checked}
                            onChange={(e) =>
                              setChecks(
                                checks.filter((c, index) => {
                                  if (key === index) {
                                    x.check = e.target.checked
                                  }
                                  return c
                                })
                              )
                            }
                            onKeyPress={(e) => {
                              e.key === 'Enter' && e.preventDefault()
                            }}
                          />

                          <input
                            className='form-control border-0 rounded-0 bg-gray-100 bg-focus-gray-100 p-0 ps-2 py-2 ms-4'
                            type='text'
                            placeholder={intl.formatMessage({id: 'CHECKLIST.NAME_NEW'})}
                            {...formik.getFieldProps('checklist_name')}
                            id='checklist_name'
                            name='checklist_name'
                            disabled={formik.isSubmitting}
                            onKeyPress={(e) => {
                              e.key === 'Enter' && e.preventDefault()
                            }}
                            value={x.content}
                            onChange={(e) => {
                              setChecks(
                                checks.filter((c, index) => {
                                  if (key === index) {
                                    c.content = e.target.value
                                  }
                                  return c
                                })
                              )
                            }}
                          ></input>

                          <button onClick={() => removeCheck(key)} className='btn p-0 ms-5'>
                            <i className='bi bi-trash3-fill'></i>
                          </button>
                        </div>
                      )
                    })}
                    <button
                      onClick={addCheck}
                      onKeyPress={(e) => {
                        e.key === 'Enter' && e.preventDefault()
                      }}
                      className='btn text-bold d-flex align-items-center p-0 mt-10'
                    >
                      <div className='d-flex align-items-center justify-content-center w-15px h-15px bg-gray-900 me-3'>
                        <i className='bi bi-plus text-gray-100 fs-5 p-0'></i>
                      </div>
                      {intl.formatMessage({id: 'GENERAL.ADD_ITEM'})}
                    </button>
                  </div>
                </div>

                <div id='kt_task_comments_pane' className='tab-pane fade'>
                  <div className='pt-4 d-flex flex-column gap-4'>
                    {task.tasks.comments?.map((comment) => (
                      <div className='d-flex align-items-center space-4'>
                        <div className='symbol symbol-circle symbol-30px me-5'>
                          {comment.user_avatar && TemaAtual === 'dark' ? (
                            <img
                              alt={comment.user_name}
                              className='object-fit-cover'
                              src={
                                isNotEmpty(comment.user_avatar) && comment.user_avatar !== 'null'
                                  ? process.env.REACT_APP_THEME_API_URL +
                                    '/storage/' +
                                    comment.user_avatar
                                  : toAbsoluteUrl('/media/svg/files/blank-image-dark.svg')
                              }
                            />
                          ) : (
                            <img
                              alt='Logo'
                              className='object-fit-cover'
                              src={
                                isNotEmpty(comment.user_avatar) && comment.user_avatar !== 'null'
                                  ? process.env.REACT_APP_THEME_API_URL +
                                    '/storage/' +
                                    comment.user_avatar
                                  : toAbsoluteUrl('/media/svg/files/blank-image.svg')
                              }
                            />
                          )}
                        </div>
                        <div className='d-flex'>
                          <p className='m-0'>
                            {comment.user_name} - {comment.description} -{' '}
                            {new Date(comment.created_at).toLocaleDateString('pt-BR', {
                              timeZone: 'UTC',
                            })}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div id='kt_task_responsible_sequence_pane' className='tab-pane fade'>
                  <div className='pt-6'>
                    <div className='timeline'>
                      {responsible.map((responsible, key) => (
                        <div className='timeline-item'>
                          <div className='timeline-line w-40px'></div>
                          <div className='timeline-icon symbol symbol-circle symbol-40px overflow-hidden me-4'>
                            <div className='symbol-label bg-light'>
                              <span className='svg-icon svg-icon-2 svg-icon-gray-500'>
                                <img src='/media/avatars/300-6.jpg' alt='Pic' className='w-100' />
                              </span>
                            </div>
                          </div>
                          <div className='timeline-content mb-10 mt-2 d-flex align-items-center justify-content-between'>
                            <div className='overflow-auto pe-3'>
                              <div className='fs-5 fw-semibold mb-2'>{responsible.name}</div>
                            </div>
                            <button onClick={() => removeResponsible(key)} className='btn p-0 ms-5'>
                              <i className='bi bi-x-square-fill'></i>
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex align-items-center justify-content-center w-15px h-15px bg-gray-900 me-3'>
                        <i className='bi bi-plus text-gray-100 fs-5 p-0'></i>
                      </div>
                      <div className='form-group'>
                        <select
                          className='form-select form-select-sm py-2 pe-8'
                          onChange={(e) => addResponsible(e.target.value)}
                        >
                          <option>{intl.formatMessage({id: 'GENERAL.ADD_RESPONSIBLE'})}</option>
                          <option value='Alice Garcia'>Alice Garcia</option>
                          <option value='André Brunelli'>André Brunelli</option>
                          <option value='Hector Lutero'>Hector Lutero</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 border-start-lg ps-lg-10'>
              <div className='border-bottom pb-8'>
                <div className='d-flex mb-4'>
                  <span className='fw-bold text-dark me-1'>
                    {intl.formatMessage({id: 'TASK.LABEL.ESTIMATED_DEADLINE'})}
                  </span>
                </div>

                <div className='d-flex mb-4'>
                  <input
                    placeholder={intl.formatMessage({id: 'FORM.INPUT.NAME.BEGIN_DATE'})}
                    type='date'
                    name='expected_date'
                    min={today}
                    value={delivery_date_input}
                    onChange={(e: any) => setDeliveryDateInput(e.target.value)}
                    onKeyPress={(e) => {
                      e.key === 'Enter' && e.preventDefault()
                    }}
                    className={clsx('form-control form-control-solid py-2  rounded fw-normal')}
                    autoComplete='off'
                  />
                </div>

                <div className='d-flex mb-4'>
                  <span className='fw-bold text-dark me-1'>
                    {intl.formatMessage({id: 'TASK.LABEL.TASK_ID'})} {data.id}
                  </span>
                </div>

                <div className='d-flex mb-4'>
                  <span className='fw-bold text-dark me-1'>
                    {intl.formatMessage({id: 'TASK.LABEL.CREATOR_DEADLINE'})} {data.creator_name}
                  </span>
                </div>

                <div className='d-flex mb-4'>
                  <span className='fw-bold text-dark me-1'>
                    {intl.formatMessage({id: 'FORM.INPUT.NAME.CREATED_AT'})} {data.created_at}
                  </span>
                </div>
              </div>
              <div className='pt-10'>
                <div>
                  <div className='d-flex fw-bold text-dark align-items-center'>
                    <span>{intl.formatMessage({id: 'TASK.LABEL.PROJECT'})} </span>
                  </div>
                  <SelectAutoComplete
                    endPoint='projetos'
                    value={{value: task.tasks.project_id, label: task.tasks.project_name!}}
                    placeholder={intl.formatMessage({id: 'FORM.INPUT.TOOLTIP.PROJECT'})}
                    onChange={(e) => {
                      formik.setFieldValue('project_id', e?.value)
                    }}
                    onCreateOption={(inputValue) => handleCreateProjectAndClient(inputValue)}
                  />
                  <Link
                    to={`/projects/${task.tasks.project_id}`}
                    className='d-flex-inline text-dark align-items-center text-decoration-underline'
                  >
                    Ir para o projeto{' '}
                    <i class='bi bi-box-arrow-up-right fw-bold text-dark ms-1'></i>
                  </Link>
                </div>
              </div>

              <div className='text-center pt-7'>
                <button
                  type='button'
                  className='btn btn-primary btn-sm px-8'
                  data-kt-users-modal-action='submit'
                  onClick={submitForm}
                  disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                >
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'GENERAL.EDIT_TASK'})}
                  </span>

                  {formik.isSubmitting && (
                    <span className='indicator-progress'>
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
        {(formik.isSubmitting || isTaskLoading) && <Loading />}
      </div>
      {/* 
      //@ts-ignore */}
      <AdjustTimeUserModal
        show={showAdjustTimeModal}
        handleClose={() => setShowAdjustTimeModal(false)}
        taskId={task.tasks.id}
      />

      <TaskCloneModal
        show={showModalClone}
        handleClose={() => setShowModalClone(false)}
        taskId={task.tasks.id}
      />

      <CreateProjectAndClient
        show={showProjectAndClientModal}
        handleClose={() => setShowProjectAndClientModal(false)}
      />
    </>
  )
}

export {TaskEditModalForm}
