import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {Link} from 'react-router-dom'

/* eslint-disable jsx-a11y/anchor-is-valid */
type Props = {
  menuTrigger?: string
}

const Helper = ({menuTrigger = "{default: 'click', lg: 'hover'}"}: Props) => {
  const intl = useIntl()
  return (
    <>
      {/* begin::Menu toggle */}
      <a
        href='#'
        className={clsx('btn btn-icon btn-active-light-primary btn-custom')}
        data-kt-menu-trigger={menuTrigger}
        data-kt-menu-attach='parent'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen046.svg' className='svg-icon-1' />
      </a>
      {/* begin::Menu toggle */}

      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3 my-0'>
          <Link to='/onboarding' className='menu-link px-3 py-2'>
            <span className='menu-icon' data-kt-element='icon'>
              <KTSVG
                path='/media/icons/duotune/general/gen022.svg'
                className='svg-icon-muted svg-icon-3'
              />
            </span>
            <span className='menu-title'>{intl.formatMessage({id: 'MENU.RE_DO_ONBOARDING'})}</span>
          </Link>
          <a href='https://help.taskrush.com.br/' target='_blank' className='menu-link px-3 py-2'>
            <span className='menu-icon' data-kt-element='icon'>
              <span className='bi bi-info-circle svg-icon-muted svg-icon-3' />
            </span>
            <span className='menu-title'>{intl.formatMessage({id: 'MENU.HELP'})}</span>
          </a>
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {Helper}
