import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import {useQuery} from 'react-query'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()

  useEffect(() => {
    if (currentUser?.has_active_time_tracking === false)
      document.title = `TaskRush - ${process.env.REACT_APP_THEME_NAME}`
  }, [currentUser?.has_active_time_tracking])

  useEffect(() => {
    if (currentUser?.has_active_time_tracking) {
      const intervalId = setInterval(
        () =>
          (document.title =
            document.title === `TaskRush - ${process.env.REACT_APP_THEME_NAME}`
              ? '▶ Tarefa em andamento'
              : `TaskRush - ${process.env.REACT_APP_THEME_NAME}`),
        1500
      )
      return () => clearInterval(intervalId)
    }
  })

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  
  const {data} = useQuery(
    ['verify-token', auth?.api_token],
    async () => {
      return (await getUserByToken(auth?.api_token!)).data
    },
    {
      retry: false,
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        setCurrentUser(data)
        setShowSplashScreen(false)
      },
      onError() {
        logout()
        setShowSplashScreen(false)
      },
    }
  )

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
