import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../helpers'

type Props = {
  className?: string
}

export const LimitUsersWidget = ({className}: Props) => (
  <div className={`card card-flush ${className}`}>
    <div
      className='card-body d-flex flex-column justify-content-between mt-9 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0'
      style={{
        backgroundPosition: '100% 50%',
        backgroundImage: `url('${toAbsoluteUrl('/media/stock/900x600/42.png')}')`,
      }}
    >
      <div className='mb-10'>
        <div className='fs-2hx fw-bold text-gray-800 text-center mb-13'>
          <p className='me-2'>
            Você atingiu o número máximo de usuários permitidos no seu plano atual.
          </p>
          <p className='fs-3'>
            Faça o upgrade para aumentar o limite e continue criando novos usuários!
          </p>
        </div>

        <div className='text-center'>
          <Link to='/billings' className='btn btn-danger'>
            Faça o Upgrade Agora
          </Link>
        </div>
      </div>
      <img
        className='mx-auto h-150px h-lg-200px  theme-light-show'
        src={toAbsoluteUrl('/media/illustrations/misc/upgrade.svg')}
        alt=''
      />
      <img
        className='mx-auto h-150px h-lg-200px  theme-dark-show'
        src={toAbsoluteUrl('/media/illustrations/misc/upgrade-dark.svg')}
        alt=''
      />
    </div>
  </div>
)
