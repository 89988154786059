import React, {FC} from 'react'
import { Link } from 'react-router-dom'

const PurchaseButton: FC = () => (
  <Link
    to='/billings'
    className='engage-purchase-link btn btn-flex h-35px bg-body bg-hover-danger btn-color-gray-700 btn-active-color-white px-5 shadow-sm rounded-top-0'
  >
    Explore mais recursos
  </Link>
)

export {PurchaseButton}
