import React, {Dispatch, useEffect, useState} from 'react'
import {KTSVG} from '../../../helpers'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {useIntl} from 'react-intl'
import Swal from 'sweetalert2'
import {useAuth} from '../../../../app/modules/auth'
import {updateSubscription} from '../../../../app/modules/billings/components/billings-header/core/_requests'
import {getCreditCards} from '../../../../app/modules/billings/components/core/_requests'
import {Modal, OverlayTrigger, Tooltip} from 'react-bootstrap'

const features = [
  {
    title: 'Projetos e Tarefas ilimitadas',
    supported: true,
  },
  {
    title: 'Gestão de clientes',
    supported: true,
  },
  {
    title: 'Timetracking',
    supported: true,
  },
  {
    title: 'Gestão de contratos',
    supported: true,
  },
  {
    title: 'Gestão de sprints',
    supported: true,
  },
  {
    title: 'Relatório Lançamentos de horas por cliente',
    supported: true,
  },
  {
    title: 'Relatório Margem de Lucro de Projetos',
    supported: true,
  },
  {
    title: 'Cronogramas',
    supported: true,
  },
  {
    title: 'Wiki',
    supported: true,
  },
  {
    title: 'Suporte pelo Whatsapp e email',
    supported: true,
  },
]

const plans = [
  {
    title: 'Individual',
    subTitle: 'Para quem quer praticidade e foco',
    description:
      'Ideal para quem busca uma experiência personalizada, com acesso a todas as ferramentas necessárias para alcançar seus objetivos de forma independente.',
    priceMonth: '19,90',
    priceAnnual: '177,00',
    custom: false,
    features,
  },
  {
    title: 'Equipe',
    subTitle: 'Para equipes em busca de resultados',
    description:
      'Perfeito para grupos e equipes que desejam maximizar a produtividade. Inclui funcionalidades avançadas para colaboração e gestão eficiente de projetos em equipe.',
    label: 'Mais popular',
    priceMonth: '44,70',
    priceAnnual: '397,00',
    custom: false,
    features,
  },
]

type Props = {
  show: boolean
  handleClose: () => void | undefined
  setOpenNewCreditCardModal: Dispatch<React.SetStateAction<boolean>>
  isOpenCreditCardModal: boolean
}

export function UpgradePlan({
  show,
  handleClose,
  setOpenNewCreditCardModal,
  isOpenCreditCardModal,
}: Props) {
  const [recurrenceType, setRecurrenceType] = useState<'MONTHLY' | 'YEARLY'>('MONTHLY')
  const intl = useIntl()
  const {currentUser} = useAuth()
  const queryClient = useQueryClient()
  const [selectedPlan, setSelectedPlan] = useState(
    currentUser?.instance?.total_users == 1 ? 'Individual' : 'Equipe'
  )
  const [usersNumber, setUsersNumber] = useState(currentUser?.instance?.total_users ?? 1)
  const [selectedCard, setSelectedCard] = useState<number>()
  const [isSubmiting, setIsSubmiting] = useState(false)
  const hasChange =
    currentUser?.instance?.active_subscription?.recurrence_type != recurrenceType ||
    currentUser.instance.total_users != usersNumber

  const {data: cards = []} = useQuery(`credit-cards`, () => getCreditCards(), {
    cacheTime: 0,
    onError: (err) => console.error(err),
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    setSelectedPlan(usersNumber > 1 ? 'Equipe' : 'Individual')
  }, [usersNumber])

  const {mutateAsync: updateFn} = useMutation(
    () =>
      updateSubscription({
        instanceId: Number(currentUser?.instance?.id),
        planName: selectedPlan == 'Individual' ? 'Plano Individual' : 'Plano Pro',
        usersNumber,
        recurrenceType,
        value: Number(getValue(selectedPlan).toFixed(2)),
        cardId: selectedCard!,
      }),
    {
      onSuccess: (data, variable) => {
        Swal.fire({
          icon: 'success',
          title: 'Assinatura atualizada.',
        })
        queryClient.invalidateQueries()
      },
      onError: (error: any) => {
        Swal.fire({
          icon: 'warning',
          text: error.response.data.message,
        })
      },
    }
  )

  function confirmUpgrade() {
    setIsSubmiting(true)
    const TemaAtual = window.localStorage.getItem('kt_theme_mode_value') || ''
    const tema = TemaAtual === 'dark' ? '#353b48' : '#fff'

    Swal.fire({
      title: 'Confirmação de Upgrade',
      html: `
      <p>Novo valor da assinatura: ${Number(getValue(selectedPlan)).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })} /${recurrenceType == 'MONTHLY' ? 'mês' : 'ano'}
      ${
        currentUser?.instance?.active_subscription &&
        usersNumber > currentUser?.instance?.total_users!
          ? `<br/> Pagamento parcial a ser cobrado agora: ${Number(
              Math.max(getPartialValue(selectedPlan), 5)
            ).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
      </p>`
          : ''
      }
      <p>Deseja confirmar o upgrade?</p>`,
      showCloseButton: true,
      background: tema,
      confirmButtonColor: '#009ef7',
      confirmButtonText: 'Confirmar',
    })
      .then(async (result) => {
        if (result.isConfirmed) await updateFn().then(() => handleClose())
      })
      .finally(() => setIsSubmiting(false))
  }

  function getValue(planName: string): number {
    if (planName == 'Individual' && recurrenceType == 'MONTHLY') return 19.9
    if (planName == 'Individual' && recurrenceType == 'YEARLY') return 17.9 * 12
    if (planName == 'Equipe' && recurrenceType == 'MONTHLY')
      return (usersNumber < 3 ? 3 : usersNumber) * 14.9
    if (planName == 'Equipe' && recurrenceType == 'YEARLY')
      return (usersNumber < 3 ? 3 : usersNumber) * 14.9 * 12 * 0.73
    return 0
  }

  function getPartialValue(planName: string): number {
    const expiryDate = new Date(currentUser?.instance?.active_subscription?.expiry_date!)
    const today = new Date()
    const numberOfDays =
      Math.floor((expiryDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)) + 1
    const diffUsers = usersNumber - currentUser?.instance?.total_users!

    if (planName == 'Equipe' && recurrenceType == 'MONTHLY')
      return (14.9 / 30) * numberOfDays * diffUsers
    if (planName == 'Equipe' && recurrenceType == 'YEARLY')
      return ((14.9 * 12 * 0.73) / 365) * numberOfDays * diffUsers

    return 0
  }

  return (
    <Modal
      id='kt_modal_upgrade_plan'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName={`modal-dialog modal-dialog-centered`}
      show={show}
      backdrop='static'
      onHide={handleClose}
      size='xl'
      style={{zIndex: isOpenCreditCardModal ? 1000 : 1050}}
    >
      <div className='modal-content'>
        <div className='modal-header justify-content-end border-0 pb-0'>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
        </div>

        <div className='modal-body pt-0 pb-15 px-5 px-xl-20'>
          <div className='mb-13 text-center'>
            <h1 className='mb-3 text-center'>Atualize seu plano</h1>

            {/* <div className='text-muted fw-bold fs-5'>
                If you need more info, please check{' '}
                <a href='#' className='link-primary fw-bolder'>
                  Pricing Guidelines
                </a>
                .
              </div> */}
          </div>
          <p className='fs-2'>
            Seu plano atual é o <b>{currentUser?.instance?.plan?.name}</b> com{' '}
            <b>{currentUser?.instance?.total_users}</b>{' '}
            {currentUser?.instance?.total_users == 1 ? 'usuário' : 'usuários'}
          </p>

          <div className='d-flex flex-column'>
            <div className='d-flex flex-column flex-sm-row align-items-center mx-auto'>
              <div className='d-flex flex-column'>
                <p className='text-center'>Quantos usuários?</p>
                <div className='input-group w-50 align-items-center mx-auto'>
                  <div className='input-group-prepend'>
                    <button
                      className='btn btn-outline-secondary border border-gray border-right-0 py-3 rounded-0'
                      type='button'
                      onClick={() => setUsersNumber((prev) => (prev > 1 ? prev - 1 : prev))}
                    >
                      -
                    </button>
                  </div>
                  <input
                    type='number'
                    className='form-control text-center border-gray border border-1 shadow-none border-left-0 border-right-0 py-3'
                    name='usersNumber'
                    id='usersNumber'
                    value={usersNumber}
                    autoComplete='off'
                    onChange={(event) =>
                      setUsersNumber(
                        Number(event.target.value) <= 0 ? 1 : Number(event.target.value)
                      )
                    }
                  />
                  <div className='input-group-append'>
                    <button
                      className='btn btn-outline-secondary border border-gray border-left-0 py-3 rounded-0'
                      type='button'
                      onClick={() => setUsersNumber((prev) => prev + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
              <div className='d-flex flex-column mt-8 mt-sm-0'>
                <p className='text-center text-primary'>
                  <b>27% off no valor anual</b>
                </p>
                <div
                  className='nav-group nav-group-outline my-0 py-1 mx-auto'
                  data-kt-buttons='true'
                >
                  <a
                    href='#'
                    className={
                      'btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 ' +
                      (recurrenceType === 'MONTHLY' && 'active')
                    }
                    onClick={() => {
                      setRecurrenceType('MONTHLY')
                    }}
                    data-kt-plan='month'
                  >
                    Mensal
                  </a>
                  <a
                    href='#'
                    className={
                      'btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 ' +
                      (recurrenceType === 'YEARLY' && 'active')
                    }
                    onClick={() => {
                      setRecurrenceType('YEARLY')
                    }}
                    data-kt-plan='annual'
                  >
                    Anual
                  </a>
                </div>
              </div>
            </div>

            <div className='row mt-10'>
              <div className='col-lg-6 mb-10 mb-lg-0'>
                <div className='nav flex-column'>
                  {plans.map((plan, index) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedPlan(plan.title)
                          setUsersNumber(plan.title == 'Individual' ? 1 : 3)
                        }}
                        className={
                          `nav-link btn btn-outline btn-outline-dashed btn-color-dark d-flex flex-stack text-start p-6 mb-6 ${
                            plan.title == selectedPlan && 'active'
                          } ` + (!plan.custom && 'btn-active btn-active-primary ')
                        }
                        data-bs-toggle='tab'
                        data-bs-target={`#kt_upgrade_plan_${index}`}
                        key={plan.title}
                      >
                        <div className='d-flex align-items-center me-2'>
                          <div className='form-check form-check-custom form-check-solid form-check-success me-6'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='plan'
                              value={plan.title}
                              checked={selectedPlan === plan.title}
                              onChange={(e) => setSelectedPlan(e.target.value)}
                            />
                          </div>

                          <div className='flex-grow-1'>
                            <h2 className='d-flex align-items-center fs-2 fw-bolder flex-wrap'>
                              {plan.title}

                              {plan.label && (
                                <span className='badge badge-light-success ms-2 fs-7'>
                                  {plan.label}
                                </span>
                              )}
                            </h2>
                            <div className='fw-bold opacity-50'>{plan.subTitle}</div>
                          </div>
                        </div>

                        <div className='ms-5'>
                          {plan.custom && (
                            <button className='btn btn-sm btn-primary'>Contact Us</button>
                          )}
                          {!plan.custom && (
                            <>
                              <span className='mb-2'>R$</span>

                              <span className='fs-3x fw-bolder'>
                                {getValue(plan.title).toLocaleString('pt-BR', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </span>

                              <span className='fs-7 opacity-50'>
                                /
                                <span data-kt-element='period'>
                                  {recurrenceType == 'MONTHLY' ? 'mês' : 'ano'}
                                </span>
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    )
                  })}

                  <div className='mt-8'>
                    <div className='d-flex justify-content-between align-items-center mb-4'>
                      <h3 className='fs-2'>Formas de pagamento</h3>
                      <button
                        className='btn btn-sm btn-primary'
                        onClick={() => setOpenNewCreditCardModal(true)}
                      >
                        Novo Cartão
                      </button>
                    </div>

                    {/* Container com rolagem para os cartões */}
                    <div
                      style={{maxHeight: '400px', overflowY: 'auto'}}
                      className='d-flex flex-column gap-4'
                    >
                      {cards.length > 0 ? (
                        cards.map((card) => (
                          <div
                            onClick={() => setSelectedCard(card.id)}
                            className={`nav-link btn btn-outline btn-color-dark d-flex flex-stack text-start px-6 py-4 ${
                              card.id == selectedCard && 'active'
                            } `}
                            key={card.id}
                          >
                            <div className='d-flex align-items-center me-2'>
                              <div className='form-check form-check-custom form-check-solid form-check-success me-6'>
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  name='card'
                                  value={card.id}
                                  checked={selectedCard === card.id}
                                  onChange={(e) => setSelectedCard(Number(e.target.value))}
                                  role='button'
                                />
                              </div>

                              <div className='flex-grow-1'>
                                <div className='d-flex gap-4 align-items-center'>
                                  <div className='symbol'>
                                    <img alt='card' src='/media/svg/card-logos/mastercard.svg' />
                                  </div>

                                  <div>
                                    <div className='d-flex align-items-center gap-3'>
                                      <span className='fs-4 text-gray-800 fw-bolder mb-0'>
                                        Cartão{' '}
                                        {card.credit_card_brand.charAt(0).toUpperCase() +
                                          card.credit_card_brand.slice(1).toLowerCase()}
                                      </span>
                                    </div>
                                    <p className='fw-bold text-gray-400 mb-0'>
                                      Final {card.credit_card_last4}{' '}
                                      {`- Válido até ${card.credit_card_expiry_date}`}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p className='text-center mt-4'>
                          Você ainda não possui um cartão cadastrado. Adicione um novo cartão para
                          habilitar o pagamento e concluir sua assinatura.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-lg-6'>
                <div className='tab-content rounded h-100 bg-light p-10'>
                  {plans.map((plan, index) => {
                    return (
                      <div key={`custom${index}`}>
                        {!plan.custom && selectedPlan == plan.title && (
                          <div
                            className={`tab-pane fade show active`}
                            id={`kt_upgrade_plan_${index}`}
                            key={index}
                          >
                            <div className='pb-5'>
                              <h2 className='fw-bolder text-dark'>
                                O que está incluído no Plano {plan.title}?
                              </h2>

                              <div className='text-gray-400 fw-bold'>{plan.description}</div>
                            </div>

                            <div className='pt-1'>
                              {plan.features!.map((feature, i) => {
                                return (
                                  <div
                                    className={
                                      `d-flex align-items-center` +
                                      (i !== plan.features!.length - 1 && ' mb-7')
                                    }
                                    key={`${i}-${feature.title}`}
                                  >
                                    {feature.supported && (
                                      <>
                                        <span className='fw-bold fs-5 text-gray-700 flex-grow-1'>
                                          {feature.title}
                                        </span>

                                        <KTSVG
                                          path='/media/icons/duotune/general/gen043.svg'
                                          className='svg-icon-1 svg-icon-success'
                                        />
                                      </>
                                    )}
                                    {!feature.supported && (
                                      <>
                                        <span className='fw-bold fs-5 text-gray-400 flex-grow-1'>
                                          {feature.title}
                                        </span>
                                        <KTSVG
                                          path='/media/icons/duotune/general/gen040.svg'
                                          className='svg-icon-1'
                                        />
                                      </>
                                    )}
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex flex-center flex-row-fluid pt-12'>
            <button type='reset' className='btn btn-light me-3' onClick={handleClose}>
              Cancel
            </button>

            {!selectedCard || !hasChange ? (
              <OverlayTrigger
                placement='top'
                delay={{show: 50, hide: 100}}
                overlay={
                  <Tooltip>
                    {!hasChange
                      ? 'Nenhuma alteração foi feita.'
                      : 'Selecione um cartão para continuar.'}
                  </Tooltip>
                }
              >
                <span>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    onClick={() => confirmUpgrade()}
                    disabled
                  >
                    Atualizar plano
                  </button>
                </span>
              </OverlayTrigger>
            ) : (
              <button
                type='submit'
                className='btn btn-primary'
                onClick={() => confirmUpgrade()}
                disabled={!selectedCard || isSubmiting}
              >
                {!isSubmiting ? (
                  'Atualizar plano'
                ) : (
                  <span className=''>
                    {intl.formatMessage({id: 'GENERAL.LOADING'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}
